import React from "react"
import styled from "styled-components"
import RpsLogo from "./images/RPS.png"

const StyledHeader = styled.div`
  display: grid;
  justify-content: center;
  margin: 0;
  width: 100%;
  background-color: #222;
  border-bottom: 2px solid rgb(85, 177, 247);
  img {
    margin: .5rem;
    width: 125px;
  }
`

const Header = () => {
  return (
    <StyledHeader>
      <img src={RpsLogo} alt="RPS Corporation" />
    </StyledHeader>
  )
}

export default Header
