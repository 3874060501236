import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Header from "../../components/academyPayments/Header"

const GlobalStyles = styled.div`
  display: grid;
  width: 100%;
  min-height: 100vh;
  align-content: flex-start;
  overflow: scroll;
  font-family: "Roboto", sans-serif;
  background-color: #222;
`
const PageLayout = styled.div`
display: grid;
padding: 2% 5%;
`

const Layout = props => {
  return (
    <GlobalStyles>
      <Helmet></Helmet>
      <Header />
      <PageLayout>{props.children}</PageLayout>
    </GlobalStyles>
  )
}

export default Layout
