import React from "react"
import { Link } from "gatsby"
import Layout from "../components/academyPayments/Layout"
import styled from "styled-components"
import "./academy.scss";

const InfoContainer = styled.div`

`


const CancelPage = () => {
  return (
    <Layout>
      <div className="cancel-page">
      <h1> Registration Canceled </h1>
      <InfoContainer>
        <p>
          You have NOT registered for the Service Academy until payment has been
          made. If this was done in error please go back and Register again.
        </p>
        <Link to="/academy-payments">
          Register
        </Link>
      </InfoContainer>
      </div>
    </Layout>
  )
}

export default CancelPage
